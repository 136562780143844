<script>
//import store from "@/store";

export default {
    name: "PzLoaderMixins",
    data: () => {
        return {
            instance: true
        }
    },
    mounted() {
        setTimeout(() => {
            this.setDown();
        }, 0);
    },
    updated() {
        setTimeout(() => {
            this.setDown();
        }, 0);
    },
    methods: {
        setDown() {
            if (this.instance) {
                this.instance = false;
                this.$store.dispatch('loader/down', {trigger: 'route_enter'});
            }
        }
    }
}
</script>
